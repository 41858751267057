<template>
    <el-container>
        <el-aside class="aside">
            <div class="logo">
                <router-link to="/">{{ vuex_project }}</router-link>
            </div>
            <el-menu router :default-active="active" background-color="#17283c" text-color="#a3bbd1" active-text-color="#fff" key="1">
                <el-menu-item index="/">
                    <i class="el-icon-house"></i>
                    首页
                </el-menu-item>
                <el-menu-item index="/createOrder" v-if="$allow(1)">
                    <i class="el-icon-document-add"></i>
                    开单
                </el-menu-item>
                <!-- 	<el-menu-item index="/clockOn"><i class="el-icon-notebook-2"></i>会员打卡</el-menu-item> -->
                <el-submenu index="7">
                    <template slot="title">
                        <i class="el-icon-goods"></i>
                        会员卡管理
                    </template>
                    <el-menu-item index="/clockOn">会员卡记录</el-menu-item>
                    <el-menu-item index="/memberRecharge">采购订单</el-menu-item>
                    <el-menu-item index="/memberRecharge/incomeStatistics">收益统计</el-menu-item>
                </el-submenu>

                <el-menu-item index="/order" v-if="$allow(2)">
                    <i class="el-icon-document-copy"></i>
                    订单
                    <el-badge :value="vuex_waitend" :max="99" v-if="vuex_waitend" />
                </el-menu-item>
                <el-menu-item index="/pickup" v-if="$allow(4)">
                    <i class="el-icon-shopping-bag-1"></i>
                    取货
                    <el-badge :value="vuex_waitgoods" :max="99" v-if="vuex_waitgoods" />
                </el-menu-item>
                <el-menu-item index="/member" v-if="$allow(5)">
                    <i class="el-icon-bank-card"></i>
                    会员管理
                </el-menu-item>
                <el-menu-item index="/document" v-if="$allow(19)">
                    <i class="el-icon-document"></i>
                    会员档案
                </el-menu-item>
                <el-menu-item index="/card/recharge" v-if="$allow(10) && Number(pid) <= 0">
                    <i class="el-icon-money"></i>
                    充值卡管理
                </el-menu-item>
                <el-submenu index="1" v-if="$allow(6)">
                    <template slot="title">
                        <i class="el-icon-files"></i>
                        项目管理
                    </template>
                    <el-menu-item index="/project" v-if="$allow(7)">项目列表</el-menu-item>
                    <el-menu-item index="/projectClassify/project" v-if="$allow(8)">分类管理</el-menu-item>
                    <!-- <el-menu-item index="/card/serve" v-if="$allow(9)">服务卡</el-menu-item> -->
                </el-submenu>
                <el-submenu index="5" v-if="$allow(23)">
                    <template slot="title">
                        <i class="el-icon-folder"></i>
                        套餐管理
                    </template>
                    <el-menu-item index="/group" v-if="$allow(24)">套餐列表</el-menu-item>
                    <el-menu-item index="/groupItem" v-if="$allow(25)">规格列表</el-menu-item>
                </el-submenu>
                <el-submenu index="2" v-if="$allow(11) && Number(pid) <= 0">
                    <template slot="title">
                        <i class="el-icon-goods"></i>
                        商品管理
                    </template>
                    <el-menu-item index="/goods" v-if="$allow(12)">商品列表</el-menu-item>
                    <el-menu-item index="/goodsClassify/goods" v-if="$allow(13)">分类管理</el-menu-item>
                </el-submenu>
                <el-menu-item index="/marketingCenter" v-if="$allow(52) && Number(pid) <= 0">
                    <i class="el-icon-present"></i>
                    营销中心
                </el-menu-item>
                <el-menu-item index="/staff" v-if="$allow(14)">
                    <i class="el-icon-user"></i>
                    技师管理
                </el-menu-item>
                <el-menu-item index="/stock" v-if="$allow(15)">
                    <i class="el-icon-box"></i>
                    库存管理
                </el-menu-item>
                <el-menu-item index="/account" v-if="$allow(16)">
                    <i class="el-icon-money"></i>
                    账户管理
                </el-menu-item>
                <el-submenu index="12" v-if="$allow(56)">
                    <template slot="title">
                        <i class="el-icon-bank-card"></i>
                        平台活动
                    </template>
                    <el-submenu index="12-1">
                        <template slot="title">
                            套餐卡
                        </template>
                        <el-menu-item index="/platformActivity/packageCard" v-if="$allow(57)">套餐卡管理</el-menu-item>
                        <el-menu-item index="/platformActivity/order" v-if="$allow(57)">订单管理</el-menu-item>
                        <el-menu-item index="/platformActivity/packageCard/award">中奖记录</el-menu-item>
                        <el-menu-item index="/platformActivity/account/index">财务记录</el-menu-item>
                        <el-menu-item index="/platformActivity/packageCard/record">购卡记录</el-menu-item>
                    </el-submenu>
                    <el-submenu index="12-2">
                        <template slot="title">
                            酷车卡
                        </template>
                        <el-menu-item index="/activity/kuqa/order">订单</el-menu-item>
                    </el-submenu>
                </el-submenu>
                <el-submenu index="6" v-if="$allow(28)">
                    <template slot="title">
                        <i class="el-icon-notebook-2"></i>
                        表格中心
                    </template>
                    <el-menu-item-group v-if="$allow(33)">
                        <template slot="title">会员</template>
                        <el-menu-item index="/tables/member" v-if="$allow(29)">会员资料</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group v-if="$allow(34)">
                        <template slot="title">顾客</template>
                        <el-menu-item index="/tables/newGuest" v-if="$allow(30)">新客咨询</el-menu-item>
                        <el-menu-item index="/tables/reserveRecord" v-if="$allow(31)">客户预约记录</el-menu-item>
                        <el-menu-item index="/tables/customerCare" v-if="$allow(32)">客户护理记录</el-menu-item>
                        <el-menu-item index="/tables/customer" v-if="$allow(35)">客户档案</el-menu-item>
                        <el-menu-item index="/tables/buyProducts" v-if="$allow(36)">购买产品记录</el-menu-item>
                        <el-menu-item index="/tables/customerOpinion" v-if="$allow(37)">客户意见</el-menu-item>
                        <el-menu-item index="/tables/customerQuestionnaire" v-if="$allow(38)">客户调查问卷</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group v-if="$allow(39)">
                        <template slot="title">开卡</template>
                        <el-menu-item index="/tables/rechargeRecordForm" v-if="$allow(40)">充值记录</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group v-if="$allow(41)">
                        <template slot="title">账户</template>
                        <el-menu-item index="/tables/finance" v-if="$allow(42)">财务报表</el-menu-item>
                        <el-menu-item index="/tables/payroll" v-if="$allow(43)">月份工资核算</el-menu-item>
                        <el-menu-item index="/tables/salary" v-if="$allow(44)">人员工资一览</el-menu-item>
                        <el-menu-item index="/tables/business" v-if="$allow(45)">日/月营业报表</el-menu-item>
                        <el-menu-item index="/tables/cashStatement" v-if="$allow(46)">现金月报表</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group v-if="$allow(47)">
                        <template slot="title">店务</template>
                        <el-menu-item index="/tables/receive" v-if="$allow(48)">产品领用表</el-menu-item>
                        <el-menu-item index="/tables/stock" v-if="$allow(49)">库存月报表</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group v-if="$allow(50)">
                        <template slot="title">营销</template>
                        <el-menu-item index="/tables/activitySummary" v-if="$allow(51)">促销活动总结表</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-submenu index="4" v-if="$allow(20)">
                    <template slot="title">
                        <i class="el-icon-coin"></i>
                        提成管理
                    </template>
                    <el-menu-item index="/salary" v-if="$allow(21)">提成统计</el-menu-item>
                    <el-menu-item index="/salary/setting" v-if="$allow(22)">提成设置</el-menu-item>
                </el-submenu>
                <el-menu-item index="/marketing" v-if="$allow(26)">
                    <i class="el-icon-sugar"></i>
                    营销推广
                </el-menu-item>
                <el-menu-item index="/store" v-if="$allow(17)">
                    <i class="el-icon-set-up"></i>
                    商户设置
                </el-menu-item>
                <el-submenu index="7" v-if="$allow(27)">
                    <template slot="title">
                        <i class="el-icon-box"></i>
                        统计中心
                    </template>
                    <el-menu-item index="/statistics/home">门店统计</el-menu-item>
                    <el-menu-item index="/statistics/survey">概况统计</el-menu-item>
                    <el-menu-item index="/statistics/artificer">技师统计</el-menu-item>
                </el-submenu>
                <el-submenu index="3" v-if="$allow(18)">
                    <template slot="title">
                        <i class="el-icon-setting"></i>
                        系统设置
                    </template>
                    <el-menu-item index="/systemUser">用户管理</el-menu-item>
                    <el-menu-item index="/systemRole">角色管理</el-menu-item>
                    <el-menu-item index="/print">打印设置</el-menu-item>
                    <!-- <el-menu-item index="/systemPower">权限管理</el-menu-item> -->
                </el-submenu>
                <el-menu-item index="/helps" v-if="$allow(53)">
                    <i class="el-icon-help"></i>
                    帮助中心
                </el-menu-item>
            </el-menu>
        </el-aside>

        <el-container class="right">
            <el-header>
                <div class="merchant-name">
                    <div class="name">
                        {{ vuex_user.merchant_name }}
                        <el-tag type="success" size="small" v-if="memexia">么么侠</el-tag>
                        <el-tag type="warning" size="small" v-if="mamaxia">妈妈侠</el-tag>
                    </div>
                    <div class="breadcrumb">
                        <el-breadcrumb separator="/">
                            <el-breadcrumb-item :to="{ path: item.path || '/' }" v-for="(item, index) in breadcrumb" :key="index">
                                <i class="el-icon-house" v-if="item.name == '首页'"></i>
                                {{ item.name }}
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                </div>
                <div class="right">
                    <div class="msg">
                        <i class="el-icon-bell"></i>
                        <!-- <el-badge :value="55" :max="99" /> -->
                    </div>
                    <i class="line"></i>
                    <div class="time">
                        <my-time></my-time>
                    </div>
                    <i class="line"></i>
                    <el-dropdown trigger="click" @command="handleCommand">
                        <div class="user-info">
                            <el-image class="user-head-img" src="https://qiniu.memexia.com/admin/momen-head.jpg" fit="cover"></el-image>
                            <div class="box">
                                <div>欢迎您！</div>
                                <div>{{ vuex_user.nickname }}</div>
                            </div>
                            <i class="el-icon-caret-bottom"></i>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="modifyPassword">修改密码</el-dropdown-item>
                            <el-dropdown-item command="logout">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-dialog :title="passwordDialog.title" :visible.sync="passwordDialog.dialogVisible" width="500px" :append-to-body="true" :close-on-click-modal="false">
                        <div>
                            <el-form ref="form" :model="password" :rules="rules" label-width="100px">
                                <el-form-item label="旧密码" prop="old_pwd" :rules="rules.required">
                                    <el-input v-model="password.old_pwd" placeholder="请输入旧密码"></el-input>
                                </el-form-item>
                                <el-form-item label="新密码" prop="pwd" :rules="rules.required">
                                    <el-input v-model="password.pwd" placeholder="请输入新密码"></el-input>
                                </el-form-item>
                                <el-form-item label="确认新密码" prop="re_pwd" :rules="rules.required">
                                    <el-input v-model="password.re_pwd" placeholder="请再次输入新密码"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="passwordDialog.dialogVisible = false">取 消</el-button>
                            <el-button type="primary" @click="changePassword" :loading="btnLoading">确 定</el-button>
                        </span>
                    </el-dialog>
                </div>
            </el-header>

            <!-- 首页 -->
            <el-main v-if="active == '/'">
                <home></home>
            </el-main>
            <!-- 其他页面 -->
            <el-main v-else>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import rules from '../common/common.rules';
import myTime from '../components/MyTime.vue';
import home from './Home.vue';
export default {
    components: {
        myTime: myTime,
        home: home,
    },
    data() {
        return {
            rules,
            brands: [],
            pid: '',
            passwordDialog: {
                title: '修改密码',
                dialogVisible: false,
            },
            btnLoading: false, // 按钮加载状态
            password: {
                old_pwd: '',
                pwd: '',
                re_pwd: '',
            },
        };
    },
    computed: {
        active() {
            console.log(this.$route.path);
            if (this.$route.meta && this.$route.meta.active) {
                return this.$route.meta.active;
            } else {
                return '/' + this.$route.path.split('/')[1];
            }
        },
        breadcrumb() {
            // 根据路由，计算面包屑
            let res = [
                {
                    name: '首页',
                    path: '/',
                },
            ];
            if (this.$route.meta && this.$route.meta.breadcrumb) {
                this.$route.meta.breadcrumb.forEach((item) => {
                    let name = item.name;
                    let hasParam = this.$route.params[item.param];
                    if (hasParam) {
                        name = item.name2;
                    }
                    res.push({
                        path: item.path,
                        name: name,
                    });
                });
            } else {
                res = this.$route.matched;
            }
            console.log(res);
            return res;
        },
        memexia() {
            return ~this.brands.indexOf('么么侠');
        },
        mamaxia() {
            return ~this.brands.indexOf('妈妈侠');
        },
    },
    mounted() {
        this.$util.getWaitCount();
        // 获取商户信息
        this.$api.store.getMerchantInfo().then((res) => {
            this.pid = res.data.pid;
            // 获取当前商家加盟的品牌
            this.$api.store
                .getServeBrandList({
                    merchant_id: res.data.id,
                })
                .then((res) => {
                    this.brands = res.data
                        .filter((item) => {
                            return item.classify.length;
                        })
                        .map((item) => {
                            return item.name;
                        });
                });
        });
    },
    methods: {
        test(val) {
            console.log(val);
        },
        //修改密码
        changePassword() {
            console.log(this.password);
            this.$api.user
                .changePwd({
                    old_pwd: this.password.old_pwd,
                    pwd: this.password.pwd,
                    re_pwd: this.password.re_pwd,
                })
                .then((res) => {
                    console.log(res);
                    if (!this.password.pwd) {
                        this.$message.error('新密码不能为空');
                    }
                    if (res.code == 200) {
                        this.$message.success('密码修改成功');
                        this.passwordDialog.dialogVisible = false;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        handleCommand(command) {
            if (command == 'logout') {
                this.$vuex('vuex_token', null);
                this.$router.replace('/login');
            } else {
                this.passwordDialog.dialogVisible = true;
            }
            // this.$message('click on item ' + command);
        },
    },
};
</script>

<style scoped lang="scss">
@import '../assets/common.scss';

// .el-breadcrumb__item >>> .is-link{
// 	font-weight: normal;
// 	color: var(--blue);
// 	&:hover{
// 		opacity: 0.6;
// 	}
// }
.el-header {
    height: $header-height !important;
    text-align: right;
    line-height: $header-height;
    background: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .merchant-name {
        text-align: left;
        line-height: 20px;
        padding: 0 20px;
        font-weight: bold;

        .breadcrumb {
            margin-top: 5px;

            & >>> .is-link {
                font-weight: normal;
            }
        }
    }

    .right {
        padding: 0 20px;
        display: flex;
        align-items: center;
        color: var(--grey);

        .el-dropdown {
            height: 40px;
        }

        .user-info {
            display: flex;
            align-items: center;

            cursor: pointer;

            .user-head-img {
                width: 40px;
                height: 40px;
                border-radius: 40px;
            }

            .box {
                line-height: 18px;
                margin-left: 10px;
                text-align: left;
                font-size: 14px;
            }

            i {
                margin-left: 5px;
            }
        }

        .line {
            border-left: 1px solid #ddd;
            width: 0px;
            height: 30px;
            // background: #ddd;
            margin: 0 15px;
        }

        .time {
            color: var(--grey);
        }

        .msg {
            height: 40px;
            font-size: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
}

.aside {
    background-color: #17283c;
    overflow-x: hidden;
    height: 100%;
    position: fixed;
    z-index: 2000;
    width: $aside-width !important;

    .el-menu-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .el-badge {
            margin-left: 10px;
        }

        .el-badge >>> .el-badge__content {
            // border: none;
            // height: 20px;
            line-height: 19px;
        }
    }

    i {
        color: inherit !important;
    }
}

.el-container.right {
    padding-left: $aside-width;
    height: 100%;
    width: 100%;
    position: fixed;
}

.logo {
    background: var(--blue);
    color: #fff;
    height: 60px;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: #fff;
        text-decoration: none;
    }
}

.el-header {
    background-color: #fff;
    color: #333;
    line-height: 60px;
    box-shadow: 0px 0px 3px #cdd7de;
    padding: 0px;
    z-index: 100;
}

.el-menu {
    border-right: none;
}

.el-main {
    padding: 0;
}
</style>

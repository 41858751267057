<template>
	<div class="wrap">
		<el-alert :title="`欢迎使用后台管理系统(v1.0)，您本次登录的时间为：${vuex_login_time}`" type="success" show-icon>
			<!-- 登录ip：190.22.12.5 -->
		</el-alert>
		<!-- 统计 -->
		<div class="flex justify-between margin-top-sm">
			<div class="flex flex-sub box">
				<div class="icon-box bg-gradual-orange flex justify-center align-center">
					<i class="el-icon-user"></i>
				</div>
				<div class="data-box flex flex-direction align-center justify-center">
					<div class="data">{{historyData.member}}</div>
					<div class="text-grey text-sm">店铺用户</div>
				</div>
			</div>
			<div class="flex flex-sub box">
				<div class="icon-box bg-gradual-red flex justify-center align-center">
					<i class="el-icon-files"></i>
				</div>
				<div class="data-box flex flex-direction align-center justify-center">
					<div class="data">{{historyData.serve}}</div>
					<div class="text-grey text-sm">服务订单</div>
				</div>
			</div>
			<div class="flex flex-sub box">
				<div class="icon-box bg-gradual-green flex justify-center align-center">
					<i class="el-icon-tickets"></i>
				</div>
				<div class="data-box flex flex-direction align-center justify-center">
					<div class="data">{{historyData.product}}</div>
					<div class="text-grey text-sm">商品订单</div>
				</div>
			</div>
			<div class="flex flex-sub box">
				<div class="icon-box bg-gradual-blue flex justify-center align-center">
					<i class="el-icon-money"></i>
				</div>
				<div class="data-box flex flex-direction align-center justify-center">
					<div class="data">￥{{income}}</div>
					<div class="text-grey text-sm">交易记录</div>
				</div>
			</div>
		</div>
		<!-- 服务统计信息 -->
		<div class="flex justify-between margin-top-sm">
			<el-card class="box-card" shadow="never">
				<div slot="header" class="flex justify-between align-center">
					<span>服务统计信息</span>
					<div>
						<el-button :type="serve == 'today' ? 'text' : 'primary'" size="small"
							@click="loadServeData('month')">本月</el-button>
						<el-button :type="serve != 'today' ? 'text' : 'primary'" size="small"
							@click="loadServeData('today')">本日</el-button>
					</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">线上下单服务</div>
					<div>{{serveData.online}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">门店开单服务</div>
					<div>{{serveData.offline}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">已完成服务</div>
					<div>{{serveData.completed}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">上门服务</div>
					<div>{{serveData.door}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">划卡服务</div>
					<div>{{serveData.memberCard}}</div>
				</div>
			</el-card>
			<!-- 订单统计信息 -->
			<el-card class="box-card" shadow="never">
				<div slot="header" class="flex justify-between align-center">
					<span>订单统计信息</span>
					<div>
						<el-button :type="order == 'today' ? 'text' : 'primary'" size="small"
							@click="loadOrderData('month')">本月</el-button>
						<el-button :type="order != 'today' ? 'text' : 'primary'" size="small"
							@click="loadOrderData('today')">本日</el-button>
					</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">待提货订单</div>
					<div>{{orderData.waitGoods}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">未处理订单</div>
					<div>{{orderData.waitEnd}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">已完成订单</div>
					<div>{{orderData.endOrder}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">购卡订单</div>
					<div>{{orderData.cardOrder}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">充值订单</div>
					<div>{{orderData.rechargeOrder}}</div>
				</div>
			</el-card>
			<!-- 会员登录统计信息 -->
			<el-card class="box-card" shadow="never">
				<div slot="header" class="flex justify-between align-center">
					<span>会员登录统计信息</span>
					<div>
						<el-button :type="member == 'today' ? 'text' : 'primary'" size="small"
							@click="loadMemberData('month')">本月</el-button>
						<el-button :type="member != 'today' ? 'text' : 'primary'" size="small"
							@click="loadMemberData('today')">本日</el-button>
					</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">到店开单人数</div>
					<div>{{memberData.paidMember}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">会员总数</div>
					<div>{{memberData.memberCount}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">办卡人数</div>
					<div>{{memberData.memberCard}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">充值人数</div>
					<div>{{memberData.memberRecharge}}</div>
				</div>
				<div class="flex justify-between">
					<div class="text-grey">线上访问人数</div>
					<div>--</div>
				</div>
			</el-card>

			<!-- 最新消息 -->
			<el-card class="box-card" shadow="never" style="display: none;">
				<div slot="header" class="flex justify-between align-center">
					<span>最新消息</span>
				</div>
				<div class="flex justify-start align-center">
					<i class="el-icon-message-solid text-yellow margin-right-sm"></i>
					<div class="line-1">消息提醒消息提醒消息提醒消息提醒消息提醒</div>
				</div>
				<div class="flex justify-start align-center">
					<i class="el-icon-message-solid text-yellow margin-right-sm"></i>
					<div class="line-1">消息提醒消息提醒消息提醒消息提醒消息提醒</div>
				</div>
				<div class="flex justify-start align-center">
					<i class="el-icon-message-solid text-yellow margin-right-sm"></i>
					<div class="line-1">消息提醒消息提醒消息提醒消息提醒消息提醒</div>
				</div>
				<div class="flex justify-start align-center">
					<i class="el-icon-message-solid text-yellow margin-right-sm"></i>
					<div class="line-1">消息提醒消息提醒消息提醒消息提醒消息提醒</div>
				</div>
				<div class="flex justify-start align-center">
					<i class="el-icon-message-solid text-yellow margin-right-sm"></i>
					<div class="line-1">消息提醒消息提醒消息提醒消息提醒消息提醒</div>
				</div>
			</el-card>
		</div>
		<div class="flex justify-between margin-top-sm">
			<!-- 会员生日提醒 -->
			<el-card class="box-card" shadow="never">
				<div slot="header" class="flex justify-between align-center">
					<span>会员生日祝福
<!--						<b>去祝福</b>-->
					</span>
					<div>
						<el-button :type="birthday == 'birthdayDay' ? 'text' : 'primary'" size="small"
									@click="loadBirthdayData('birthdayMonth')">本月</el-button>
						<el-button :type="birthday != 'birthdayDay' ? 'text' : 'primary'" size="small"
									@click="loadBirthdayData('birthdayDay')">本日</el-button>
					</div>
				</div>
				<div style="height: 200px;overflow-y: auto">
					<div class="flex justify-between" v-for="(item,index) in birthdayData" :key="index" style="margin-bottom: 10px">
						<div class="text-grey" style="min-width: 80px">{{item.real_name}}</div>
						<div class="text-grey">{{item.phone}}</div>
						<div class="text-grey">
							<img src="../assets/birthday.png" alt="" style="width: 18px;height: 18px;vertical-align: middle;margin-right: 10px">
							{{item.birthday.substring(0,10)}}
						</div>

					</div>
					<div style="text-align: center;color: rgb(135, 153, 163)" v-if="birthdayData.length == 0">当前暂无会员过生日</div>
				</div>
			</el-card>
			<!-- 库存预警 -->
			<el-card class="box-card" shadow="never">
				<div slot="header" class="flex justify-start align-center">
					<span>库存预警 <b @click="replenish">去补货</b></span>
				</div>
				<div style="height: 200px;overflow-y: auto">
					<div class="flex justify-between" v-for="item in pWarning" :key="item.id" style="margin-bottom: 10px">
						<div class="text-grey" style="width: 200px">{{item.name}}</div>
						<div class="text-grey">品牌</div>
						<div style="color: #ff0000">{{item.stoke_warning}}</div>
					</div>
					<div class="flex justify-between" v-for="item in zWarning" :key="item.id" style="margin-bottom: 10px">
						<div class="text-grey" style="width: 200px">{{item.name}}</div>
						<div class="text-grey">自营</div>
						<div style="color: #FF0000">{{item.stoke_warning}}</div>
					</div>
				</div>
			</el-card>
			<div style="width: 538px;margin: 10px">
<!--				<div class="flex justify-start">-->
<!--					<div class="flex flex-direction quickly align-center justify-center margin-right bg-gradual-orange"-->
<!--						 @click="$router.push('/createOrder')">-->
<!--						<div class="bottom-icon">-->
<!--							<i class="el-icon-document-add"></i>-->
<!--						</div>-->
<!--						<div>去开单</div>-->
<!--					</div>-->
<!--					<div class="flex flex-direction quickly align-center justify-center margin-right bg-gradual-red"-->
<!--						 @click="$router.push('/order')">-->
<!--						<div class="bottom-icon">-->
<!--							<i class="el-icon-document-copy"></i>-->
<!--						</div>-->
<!--						<div>管理订单</div>-->
<!--					</div>-->
<!--					<div class="flex flex-direction quickly align-center justify-center margin-right bg-gradual-green"-->
<!--						 @click="$router.push('/member')">-->
<!--						<div class="bottom-icon">-->
<!--							<i class="el-icon-bank-card"></i>-->
<!--						</div>-->
<!--						<div>会员信息</div>-->
<!--					</div>-->
<!--					<div class="flex flex-direction quickly align-center justify-center margin-right bg-gradual-blue"-->
<!--						 @click="$router.push('/systemUser')">-->
<!--						<div class="bottom-icon">-->
<!--							<i class="el-icon-setting"></i>-->
<!--						</div>-->
<!--						<div>系统设置</div>-->
<!--					</div>-->
<!--				</div>-->
				<div class="flex align-center justify-start margin-top-lg padding-top">
					<div class="qrcode-box">
						<el-image style="width: 150px; height: 150px;border: 1px solid #ddd;" v-if="qrcode.mini_qrcode" :src="qrcode.mini_qrcode" fit="cover">
						</el-image>
					</div>
					<div class="margin-left">
						<div class="margin-bottom">门店自营商城-微信小程序端</div>
					</div>
				</div>
				<div class="flex align-center justify-start margin-top-lg padding-top">
					<div class="qrcode-box">
						<el-image style="width: 150px; height: 150px;border: 1px solid #ddd;" v-if="qrcode.image" :src="qrcode.image" fit="cover">
						</el-image>
					</div>
					<div class="margin-left">
						<div class="margin-bottom">门店自营商城-微信手机端</div>
						<el-input placeholder="请输入内容" v-model="qrcode.h5Address" style="width: 400px;" readonly>
							<el-button slot="append" icon="el-icon-document-copy" @click="copyUrl()">复制</el-button>
						</el-input>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				serve: 'today',
				order: 'today',
				member: 'today',
				birthday: 'birthdayDay',
				birthdayDay: '',//生日 天
				birthdayMonth: '',//生日 月
				// 本日
				today: {
					start_date: '',
					end_date: ''
				},
				// 本月
				month: {
					start_date: '',
					end_date: ''
				},
				orderData: {
					cardOrder: 0, // 购卡订单
					endOrder: 0, // 已完成订单
					rechargeOrder: 0, // 充值订单
					waitEnd: 0, /// 待结算订单
					waitGoods: 0 // 待提货订单
				},
				serveData: {
					completed: 0, //已完成服务
					door: 0, //上门服务
					memberCard: 0, //划卡服务
					offline: 0, //门店开单服务
					online: 0, //线上下单服务
				},
				memberData: {
					paidMember: 0, //	到店开单人数
					memberCount: 0, //	会员总数
					memberCard: 0, //	办卡人数
					memberRecharge: 0, //	充值人数
				},
				historyData: {
					member: 0, // 店铺用户
					balance: 0, // 交易记录
					product: 0, // 商品订单
					serve: 0 // 服务订单
				},
				birthdayData:[],
				pWarning:[],
				zWarning:[],
				qrcode: {
					mini_qrcode:'',
					h5Address: '',
					image: ''
				}

			};
		},
		computed: {
			income() {
				return this.$util.toMoney(this.historyData.balance || 0)
			}
		},
		mounted() {
			let today = this.$util.date.getToday()
			let month = this.$util.date.getThisMonth()
			this.today.start_date = today.startDate + ' 00:00:00';
			this.today.end_date = today.endDate + ' 23:59:59';
			this.month.start_date = month.startDate + ' 00:00:00';
			this.month.end_date = month.endDate + ' 23:59:59';
			this.loadServeData('today')
			this.loadOrderData('today')
			this.loadMemberData('today')
			this.loadBirthdayData('birthday')
			this.loadWarning()
			this.$api.statistics.history().then(res => {
				this.historyData = res.data;
			})
			// 获取商户信息
			this.$api.store.getMerchantInfo().then(res => {
				console.log(res)
				// this.qrcode.mini_qrcode = `${res.data.mini_qrcode}?time=${new Date().getTime()}`;
				this.qrcode.mini_qrcode = 'https://qiniu.memexia.com/memexia/memexia_sun_code.jpg'
				this.qrcode.image = `${res.data.h5_qrcode}?time=${new Date().getTime()}`;
				this.qrcode.h5Address = `http://h5.memexia.com?merchant_id=${res.data.id}`
			});
		},
		methods: {
			//去补货
			replenish(){
				this.$router.push("/stock");
			},
			copyUrl() {
				this.$copyText(this.qrcode.h5Address).then(() => {
					this.$message.success('复制成功')
				}, function() {
					this.$message.error('复制失败')
				});
			},
			loadServeData(type) {
				this.serve = type;
				let form = type == 'today' ? this.today : this.month;
				this.$api.statistics.serve(form).then(res => {
					this.serveData = res.data;
				})
			},
			loadOrderData(type) {
				this.order = type;
				let form = type == 'today' ? this.today : this.month;
				this.$api.statistics.order(form).then(res => {
					this.orderData = res.data;
				})
			},
			loadMemberData(type) {
				this.member = type;
				let form = type == 'today' ? this.today : this.month;
				this.$api.statistics.member(form).then(res => {
					this.memberData = res.data;
				})
			},
			//会员生日
			loadBirthdayData(type) {
				console.log(type)
				this.birthday = type;
				var birthdayTime = new Date();
				this.birthdayMonth = birthdayTime.format('MM'); //本月
				if (type != 'birthdayDay'){
					this.birthdayDay = ''
				}else {
					this.birthdayDay = birthdayTime.format('dd'); //今天
				}
				console.log(this.birthdayDay)
				this.$api.statistics.getBirthday({
					month:this.birthdayMonth,
					day:this.birthdayDay,
					page:1,
					page_size:1000
				}).then(res => {
					console.log(res)
					this.birthdayData = res.data.data;
				})
			},
			//库存预警
			loadWarning() {
				this.$api.statistics.pWarning({
					page:1,
					page_size:1000
				}).then(res => {
					console.log(res.data)
					this.pWarning = res.data.data;
				});
				this.$api.statistics.zWrning({
					page:1,
					page_size:1000
				}).then(res => {
					this.zWarning = res.data.data;
				})
			},
		},
	};
</script>

<style scoped lang="scss">
	.box {
		height: 100px;
		margin: 10px;

		.icon-box {
			width: 50%;
			border-radius: 10px 0 0 10px;
			font-size: 30px;
		}

		.data-box {
			border: 1px solid #eee;
			border-left: none;
			flex: 1;
			border-radius: 0px 10px 10px 0px;

			.data {
				font-size: 30px;
			}
		}
	}

	.bottom-icon {
		font-size: 30px;
	}

	.quickly {
		width: 100px;
		height: 100px;
		border-radius: 10px;
		cursor: pointer;
	}

	.box-card {
		width: 538px;
		line-height: 26px;
		margin: 10px;
		flex: 1;

		&>>>.el-card__header {
			padding: 5px 20px;
			height: 43px;
			line-height: 32px;
		}
		b{
			font-size: 14px;
			color: #FF4343;
			font-weight: normal;
			margin-left: 5px;
			cursor: pointer;
		}
	}
</style>

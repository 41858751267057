<template>
	<div class="time">{{time}}</div>
</template>
<script>
	export default {
		data() {
			return {
				time: ''
			};
		},
		mounted() {
			this.time = new Date().format('yyyy年MM月dd日 hh:mm:ss')
			setInterval(() => {
				this.time = new Date().format('yyyy年MM月dd日 hh:mm:ss')
			}, 1000)
		},
		methods: {

		},
	};
</script>

<style scoped lang="scss">
.time{
	width: auto;
	text-align: center;
}
</style>
